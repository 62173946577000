import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { Api } from '@dashboard/lib/api'
import { APIResponse } from '@dashboard/lib/types'

export interface ChargeResponse {
  customer: { address: string } | null
  charges: UserCharge[]
}

export interface ChargeLineItem {
  description: string
  amount: number
  quantity: number
  plan_id: string | null
}

export interface UserCharge {
  amount: string
  charge_id: string
  date: string
  description: string
  invoice_id: string
  last_four: string
  amount_discount: number | null
  percent_discount: number | null
  line_items: ChargeLineItem[]
  quantity: number
  metadata: { names?: string } | null
  original_date: number
  plan: string
  refunded: false
  status: string
  type: string
  url: string
  customer_metadata: {
    receipt: string
  } | null
}

export const REQUEST = 'GET_CHARGES_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'GET_CHARGES_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  charges: ChargeResponse
}
const requestSuccess = (charges: ChargeResponse): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  charges,
})

export const REQUEST_FAILURE = 'GET_CHARGES_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error: unknown
}
const requestFailure = (error: unknown): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function getCharges(
  starting_after: string | null = null,
  ending_before: string | null = null,
) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<ChargeResponse>((resolve, reject) => {
      dispatch(request())

      const params = {
        starting_after: starting_after,
        ending_before: ending_before,
      }

      Api()
        .get<APIResponse<ChargeResponse>>('/charges', { params })
        .then(({ data }) => {
          dispatch(requestSuccess(data.data))
          resolve(data.data)
        })
        .catch(err => {
          dispatch(requestFailure(err))
          reject(
            `Error calling getCharges (Status: ${
              err.response?.status
            }): ${JSON.stringify(err.response?.data)}`,
          )
        })
    })
  }
}
