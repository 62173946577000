import * as assignProductLicenseActions from '@dashboard/store/actions/user/products/assign'
import * as ConnectSocialActions from '@dashboard/store/actions/user/connect_social'
import * as createActionActions from '@dashboard/store/actions/user/action/create'
import * as createAPICustomerTokenActions from '@dashboard/store/actions/api_customer/create_token'
import * as APICustomerStatsActions from '@dashboard/store/actions/api_customer/stats'
import * as createProductActions from '@dashboard/store/actions/user/products/create'
import * as deleteActions from '@dashboard/store/actions/user/delete'
import * as deleteCreditsActions from '@dashboard/store/actions/user/credits/spend'
import * as deleteNotificationsActions from '@dashboard/store/actions/user/notifications/deleteNotifications'
import * as deleteProductActions from '@dashboard/store/actions/user/products/delete'
import * as unassignProductLicenseActions from '@dashboard/store/actions/user/products/unassign'
import * as demographicsGetActions from '@dashboard/store/actions/user/demographics/get'
import * as demographicsSubmitActions from '@dashboard/store/actions/user/demographics/submit'
import * as demographicsUpdateActions from '@dashboard/store/actions/user/demographics/update'
import * as emailConfirmActions from '@dashboard/store/actions/user/emails/confirm'
import * as emailCreateActions from '@dashboard/store/actions/user/emails/create'
import * as emailPrimaryActions from '@dashboard/store/actions/user/emails/makePrimary'
import * as emailRemoveActions from '@dashboard/store/actions/user/emails/remove'
import * as getNotificationsActions from '@dashboard/store/actions/user/notifications/getNotifications'
import * as inviteIndividualUser from '@dashboard/store/actions/user/inviteIndividual'
import * as invitePeerAssessments from '@dashboard/store/actions/user/invitePeerAssessments'
import * as emailInviteAssessment from '@dashboard/store/actions/user/emailInviteAssessment'
import * as inviteUsers from '@dashboard/store/actions/user/invite'
import * as loginActions from '@dashboard/store/actions/user/login'
import * as meActions from '@dashboard/store/actions/user/me'
import * as passwordEmailActions from '@dashboard/store/actions/user/password/email'
import * as passwordResetActions from '@dashboard/store/actions/user/password/reset'
import * as passwordChangeActions from '@dashboard/store/actions/user/password/change'
import * as reactivateProductActions from '@dashboard/store/actions/user/products/reactivate'
import * as registrationActions from '@dashboard/store/actions/user/registration'
import * as signupActions from '@dashboard/store/actions/user/signup'
import * as sendReceiptActions from '@dashboard/store/actions/user/send_receipt'
import * as updateActions from '@dashboard/store/actions/user/update'
import * as updateSettingsActions from '@dashboard/store/actions/user/updateSettings'
import * as updatePrivacyActions from '@dashboard/store/actions/user/updatePrivacy'
import * as updateProductSubscriptionActions from '@dashboard/store/actions/user/products/updateSubscription'
import * as pauseProductActions from '@dashboard/store/actions/user/products/pause'
import * as previewProductActions from '@dashboard/store/actions/user/products/preview'
import * as getChargesActions from '@dashboard/store/actions/user/charges'
import * as getChargeActions from '@dashboard/store/actions/user/charges/show'
import * as getIntegrationTokensActions from '@dashboard/store/actions/user/integrations'
import * as createIntegrationTokenActions from '@dashboard/store/actions/user/integrations/create'
import * as updateIntegrationTokenActions from '@dashboard/store/actions/user/integrations/update'
import * as getChecklistActions from '@dashboard/store/actions/user/checklists'
import * as updateChecklistActions from '@dashboard/store/actions/user/checklists/update'
import * as completeChecklistItemActions from '@dashboard/store/actions/user/checklists/completeItem'
import * as qrActions from '@dashboard/store/actions/user/password/qr'
import * as updateProductActions from '@dashboard/store/actions/user/products/update'
import * as loadCrmIntegrationsActions from '@dashboard/store/actions/user/crmIntegrations'
import * as updatecrmIntegrationActions from '@dashboard/store/actions/user/crmIntegrations/update'
import * as findcrmIntegrationActions from '@dashboard/store/actions/user/crmIntegrations/find'
import * as connectSalesforceApiActions from '@dashboard/store/actions/user/crmIntegrations/api'
import * as criteriaActions from '@dashboard/store/actions/user/criteria'
import * as suggestCompanyActions from '@dashboard/store/actions/user/company'
import * as createToastAction from '@dashboard/store/actions/user/toast/create'
import * as dismissToastAction from '@dashboard/store/actions/user/toast/update'

import {
  LoadingStates,
  FullAuthUser,
  SubscriptionStates,
  KeyedStateHelper,
  APIPagedResponse,
  ToastMessage,
} from '@dashboard/lib/types'
import { ChargeResponse } from '@dashboard/store/actions/user/charges'
import { SingleChargeResponse } from '@dashboard/store/actions/user/charges/show'
import { PreviewResponse } from '@dashboard/store/actions/user/products/preview'
import { SalesforceApiResponse } from '@dashboard/store/actions/user/crmIntegrations/api'
import { setCookie } from '@dashboard/lib/browser'
import { AUTH_REFRESH_COOKIE } from '@crystalknows-inc/crystal-eyes/lib/constants'

export interface State {
  meState: LoadingStates
  me: null | FullAuthUser
  addPolicyState: LoadingStates
  APICustomerId: string | null
  APICustomerToken: string | null
  APICustomerTokenState: LoadingStates
  APICustomerStatsState: LoadingStates
  APICustomerStats: null | APICustomerStatsActions.CustomerStatsResponse
  assignProductLicenseState: LoadingStates
  bundleState: LoadingStates
  charges: ChargeResponse
  chargesState: LoadingStates
  connectSocialState: LoadingStates
  createProductStates: Partial<Record<string, SubscriptionStates>>
  deletePolicyState: LoadingStates
  deleteProductStates: LoadingStates
  deleteState: LoadingStates
  demographicsData: demographicsGetActions.DemographicsResponse | null
  demographicsGetState: LoadingStates
  demographicsState: LoadingStates
  emailConfirmState: LoadingStates
  emailCreateState: LoadingStates
  emailPrimaryStates: KeyedStateHelper<LoadingStates>
  emailRemoveStates: KeyedStateHelper<LoadingStates>
  inviteIndividualUserStates: KeyedStateHelper<LoadingStates>
  invitePeerAssessmentsStates: KeyedStateHelper<LoadingStates>
  emailInviteAssessmentStates: LoadingStates
  inviteUsersState: LoadingStates
  notifications: getNotificationsActions.UserNotification[]
  notificationsDeleteState: LoadingStates
  notificationsState: LoadingStates
  passwordEmailError: string
  passwordEmailState: LoadingStates
  passwordResetState: LoadingStates
  paymentState: LoadingStates
  photoState: LoadingStates
  reactivateProductStates: LoadingStates
  chargeStates: KeyedStateHelper<LoadingStates>
  chargeResults: KeyedStateHelper<SingleChargeResponse>
  registrationState: {
    state: LoadingStates
    message: string
    parameter: string
  }
  signupState: {
    state: LoadingStates
    message: string
    parameter: string
  }
  role: string | null
  sendReceiptStates: KeyedStateHelper<LoadingStates>
  deleteCreditState: LoadingStates
  deleteCreditTypeState: KeyedStateHelper<LoadingStates>
  submitQuickQuizState: LoadingStates
  unauthorized: boolean
  updateCreditCardState: SubscriptionStates
  updatePrivacyState: LoadingStates
  updateProductSubscriptionStates: LoadingStates
  updateState: LoadingStates
  updateSettingsState: LoadingStates
  loginState: LoadingStates | 'two_factor' | 'forbidden'
  createActionState: LoadingStates
  unassignProductLicenseStates: LoadingStates
  passwordChangeState: LoadingStates
  pauseProductStates: LoadingStates
  getIntegrationTokensStates: KeyedStateHelper<LoadingStates>
  getIntegrationTokenResults: KeyedStateHelper<
    APIPagedResponse<getIntegrationTokensActions.IntegrationTokenResponse>
  >
  createIntegrationTokenStates: KeyedStateHelper<LoadingStates>
  updateIntegrationTokenStates: KeyedStateHelper<LoadingStates>
  checklist: null | getChecklistActions.ChecklistResponse
  checklistState: LoadingStates
  updateChecklistState: LoadingStates
  completeChecklistItemStates: KeyedStateHelper<LoadingStates>
  qrState: LoadingStates
  qrResult: string | null
  updateProductStates: KeyedStateHelper<LoadingStates>
  previewProductStates: KeyedStateHelper<LoadingStates>
  previewProductResults: KeyedStateHelper<PreviewResponse>
  crmIntegrationsState: LoadingStates
  crmIntegrations: loadCrmIntegrationsActions.CrmIntegrationObject[]
  updateCrmIntegrationStates: KeyedStateHelper<LoadingStates>
  findCrmIntegrationStates: KeyedStateHelper<LoadingStates>
  findCrmIntegrationResults: KeyedStateHelper<loadCrmIntegrationsActions.CrmIntegrationObject>
  connectSalesforceApiState: LoadingStates
  connectSalesforceApiResult: SalesforceApiResponse | null
  criteriaStates: KeyedStateHelper<LoadingStates>
  criteriaResults: KeyedStateHelper<criteriaActions.UserCriteriaResponse>
  suggestedCompanies: suggestCompanyActions.SuggestedCompaniesResponse[]
  toastMessages: ToastMessage[]
}

const INITIAL_STATE: State = {
  meState: 'default',
  me: null,
  addPolicyState: 'default',
  APICustomerId: null,
  APICustomerToken: null,
  APICustomerTokenState: 'default',
  assignProductLicenseState: 'default',
  bundleState: 'default',
  charges: {
    customer: null,
    charges: [],
  },
  chargesState: 'default',
  connectSocialState: 'default',
  createProductStates: {},
  deletePolicyState: 'default',
  deleteProductStates: 'default',
  deleteState: 'default',
  demographicsData: null,
  demographicsGetState: 'default',
  demographicsState: 'default',
  emailConfirmState: 'default',
  emailCreateState: 'default',
  emailPrimaryStates: {},
  emailRemoveStates: {},
  inviteIndividualUserStates: {},
  invitePeerAssessmentsStates: {},
  emailInviteAssessmentStates: 'default',
  inviteUsersState: 'default',
  notifications: [],
  notificationsDeleteState: 'default',
  notificationsState: 'default',
  passwordEmailError: '',
  passwordEmailState: 'default',
  passwordResetState: 'default',
  paymentState: 'default',
  photoState: 'default',
  reactivateProductStates: 'default',
  chargeResults: {},
  chargeStates: {},
  registrationState: { state: 'default', message: '', parameter: '' },
  signupState: { state: 'default', message: '', parameter: '' },
  role: null,
  sendReceiptStates: {},
  deleteCreditState: 'default',
  deleteCreditTypeState: {},
  submitQuickQuizState: 'default',
  unauthorized: false,
  updateCreditCardState: { state: 'default', err: '' },
  updatePrivacyState: 'default',
  updateProductSubscriptionStates: 'default',
  updateState: 'default',
  updateSettingsState: 'default',
  APICustomerStatsState: 'default',
  APICustomerStats: null,
  loginState: 'default',
  createActionState: 'default',
  unassignProductLicenseStates: 'default',
  passwordChangeState: 'default',
  pauseProductStates: 'default',
  getIntegrationTokensStates: {},
  getIntegrationTokenResults: {},
  createIntegrationTokenStates: {},
  updateIntegrationTokenStates: {},
  checklist: null,
  checklistState: 'default',
  updateChecklistState: 'default',
  completeChecklistItemStates: {},
  qrState: 'default',
  qrResult: null,
  updateProductStates: {},
  previewProductStates: {},
  previewProductResults: {},
  crmIntegrationsState: 'default',
  crmIntegrations: [],
  updateCrmIntegrationStates: {},
  findCrmIntegrationStates: {},
  findCrmIntegrationResults: {},
  connectSalesforceApiState: 'default',
  connectSalesforceApiResult: null,
  criteriaStates: {},
  criteriaResults: {},
  suggestedCompanies: [],
  toastMessages: [],
}

type Action =
  | assignProductLicenseActions.Action
  | ConnectSocialActions.Action
  | createActionActions.Action
  | createAPICustomerTokenActions.Action
  | APICustomerStatsActions.Action
  | createProductActions.Action
  | deleteActions.Action
  | deleteCreditsActions.Action
  | deleteNotificationsActions.Action
  | deleteProductActions.Action
  | unassignProductLicenseActions.Action
  | demographicsGetActions.Action
  | demographicsSubmitActions.Action
  | demographicsUpdateActions.Action
  | emailConfirmActions.Action
  | emailCreateActions.Action
  | emailPrimaryActions.Action
  | emailRemoveActions.Action
  | getChargesActions.Action
  | getNotificationsActions.Action
  | inviteIndividualUser.Action
  | invitePeerAssessments.Action
  | emailInviteAssessment.Action
  | inviteUsers.Action
  | loginActions.Action
  | meActions.Action
  | passwordEmailActions.Action
  | passwordResetActions.Action
  | passwordChangeActions.Action
  | reactivateProductActions.Action
  | registrationActions.Action
  | signupActions.Action
  | sendReceiptActions.Action
  | updateActions.Action
  | updateSettingsActions.Action
  | updatePrivacyActions.Action
  | updateProductSubscriptionActions.Action
  | pauseProductActions.Action
  | getChargeActions.Action
  | getIntegrationTokensActions.Action
  | createIntegrationTokenActions.Action
  | updateIntegrationTokenActions.Action
  | getChecklistActions.Action
  | updateChecklistActions.Action
  | completeChecklistItemActions.Action
  | qrActions.Action
  | updateProductActions.Action
  | previewProductActions.Action
  | loadCrmIntegrationsActions.Action
  | updatecrmIntegrationActions.Action
  | findcrmIntegrationActions.Action
  | connectSalesforceApiActions.Action
  | criteriaActions.Action
  | suggestCompanyActions.Action
  | createToastAction.Action
  | dismissToastAction.Action

export default function userReducer(
  state: State = INITIAL_STATE,
  action: Action,
): State {
  switch (action.type) {
    // Get Public Relationship Data
    case meActions.REQUEST:
      return {
        ...state,
        meState: 'loading',
      }
    case meActions.REQUEST_SUCCESS:
      return {
        ...state,
        meState: 'success',
        me: action.data,
      }
    case meActions.REQUEST_FAILURE:
      return {
        ...state,
        meState: 'failure',
      }

    case meActions.REQUEST_UNAUTHORIZED:
      return { ...state, meState: 'failure', unauthorized: true }

    // ACTION CREATE
    case createActionActions.REQUEST:
      return { ...state, createActionState: 'loading' }

    case createActionActions.REQUEST_SUCCESS:
      return {
        ...state,
        createActionState: 'success',
      }

    case createActionActions.REQUEST_FAILURE:
      return { ...state, createActionState: 'failure' }

    // LOGIN
    case loginActions.REQUEST:
      return { ...state, loginState: 'loading' }

    case loginActions.REQUEST_SUCCESS: {
      const profileId = sessionStorage.getItem('profileId')
      if (profileId) {
        sessionStorage.removeItem('profileId')
      }

      setCookie(AUTH_REFRESH_COOKIE, action.token)

      return {
        ...state,
        unauthorized: false,
        loginState: 'success',
      }
    }

    case loginActions.REQUEST_TWO_FACTOR:
      return { ...state, loginState: 'two_factor' }

    case loginActions.REQUEST_FORBIDDEN:
      return { ...state, loginState: 'forbidden' }

    case loginActions.REQUEST_FAILURE:
      return { ...state, loginState: 'failure' }

    // REGISTRATION
    case registrationActions.REQUEST:
      return {
        ...state,
        registrationState: { state: 'loading', parameter: '', message: '' },
      }

    case registrationActions.REQUEST_SUCCESS: {
      const profileId = sessionStorage.getItem('profileId')
      if (profileId) {
        sessionStorage.removeItem('profileId')
      }
      setCookie(AUTH_REFRESH_COOKIE, action.token)

      return {
        ...state,
        registrationState: { state: 'success', parameter: '', message: '' },
      }
    }

    case registrationActions.REQUEST_FAILURE:
      return {
        ...state,
        registrationState: {
          state: 'failure',
          parameter: action.error?.parameter || '',
          message: action.error?.message || '',
        },
      }

    //SIGN UP
    case signupActions.REQUEST:
      return {
        ...state,
        signupState: { state: 'loading', parameter: '', message: '' },
      }

    case signupActions.REQUEST_SUCCESS: {
      const profileId = sessionStorage.getItem('profileId')
      if (profileId) {
        sessionStorage.removeItem('profileId')
      }
      setCookie(AUTH_REFRESH_COOKIE, action.token)

      return {
        ...state,
        signupState: { state: 'success', parameter: '', message: '' },
      }
    }

    case signupActions.REQUEST_FAILURE:
      return {
        ...state,
        signupState: {
          state: 'failure',
          parameter: action.error?.parameter || '',
          message: action.error?.message || '',
        },
      }

    case getChargesActions.REQUEST:
      return { ...state, chargesState: 'loading' }

    case getChargesActions.REQUEST_SUCCESS:
      return {
        ...state,
        chargesState: 'success',
        charges: action.charges,
      }

    case getChargesActions.REQUEST_FAILURE:
      return { ...state, chargesState: 'failure' }

    case sendReceiptActions.REQUEST:
      return {
        ...state,
        sendReceiptStates: {
          ...state.sendReceiptStates,
          [action.chargeId]: 'loading',
        },
      }

    case sendReceiptActions.REQUEST_SUCCESS:
      return {
        ...state,
        sendReceiptStates: {
          ...state.sendReceiptStates,
          [action.chargeId]: 'success',
        },
      }

    case sendReceiptActions.REQUEST_FAILURE:
      return {
        ...state,
        sendReceiptStates: {
          ...state.sendReceiptStates,
          [action.chargeId]: 'failure',
        },
      }

    case updateActions.REQUEST:
      return { ...state, updateState: 'loading' }

    case updateActions.REQUEST_SUCCESS:
      return { ...state, updateState: 'success' }

    case updateActions.REQUEST_FAILURE:
      return { ...state, updateState: 'failure' }

    case updateSettingsActions.REQUEST:
      return { ...state, updateSettingsState: 'loading' }

    case updateSettingsActions.REQUEST_SUCCESS:
      return { ...state, updateSettingsState: 'success', me: action.data }

    case updateSettingsActions.REQUEST_FAILURE:
      return { ...state, updateSettingsState: 'failure' }

    case deleteActions.REQUEST:
      return { ...state, deleteState: 'loading' }

    case deleteActions.REQUEST_SUCCESS:
      return { ...state, deleteState: 'success' }

    case deleteActions.REQUEST_FAILURE:
      return { ...state, deleteState: 'failure' }

    case passwordEmailActions.REQUEST:
      return { ...state, passwordEmailState: 'loading' }

    case passwordEmailActions.REQUEST_SUCCESS:
      return { ...state, passwordEmailState: 'success' }

    case passwordEmailActions.REQUEST_FAILURE: {
      return {
        ...state,
        passwordEmailState: 'failure',
        passwordEmailError: action.error || '',
      }
    }

    case passwordResetActions.REQUEST:
      return { ...state, passwordResetState: 'loading' }

    case passwordResetActions.REQUEST_SUCCESS:
      setCookie(AUTH_REFRESH_COOKIE, action.token)

      return { ...state, passwordResetState: 'success' }

    case passwordResetActions.REQUEST_FAILURE:
      return { ...state, passwordResetState: 'failure' }

    case passwordChangeActions.REQUEST:
      return { ...state, passwordChangeState: 'loading' }

    case passwordChangeActions.REQUEST_SUCCESS:
      return { ...state, passwordChangeState: 'success' }

    case passwordChangeActions.REQUEST_FAILURE:
      return { ...state, passwordChangeState: 'failure' }

    case emailCreateActions.REQUEST:
      return { ...state, emailCreateState: 'loading' }

    case emailCreateActions.REQUEST_SUCCESS:
      return { ...state, emailCreateState: 'success' }

    case emailCreateActions.REQUEST_FAILURE:
      return { ...state, emailCreateState: 'failure' }

    case emailPrimaryActions.REQUEST:
      return {
        ...state,
        emailPrimaryStates: {
          ...state.emailPrimaryStates,
          [action.email]: 'loading',
        },
      }

    case emailPrimaryActions.REQUEST_SUCCESS:
      return {
        ...state,
        emailPrimaryStates: {
          ...state.emailPrimaryStates,
          [action.email]: 'success',
        },
      }

    case emailPrimaryActions.REQUEST_FAILURE:
      return {
        ...state,
        emailPrimaryStates: {
          ...state.emailPrimaryStates,
          [action.email]: 'failure',
        },
      }

    case emailRemoveActions.REQUEST:
      return {
        ...state,
        emailRemoveStates: {
          ...state.emailRemoveStates,
          [action.email]: 'loading',
        },
      }

    case emailRemoveActions.REQUEST_SUCCESS:
      return {
        ...state,
        emailRemoveStates: {
          ...state.emailRemoveStates,
          [action.email]: 'success',
        },
      }

    case emailRemoveActions.REQUEST_FAILURE:
      return {
        ...state,
        emailRemoveStates: {
          ...state.emailRemoveStates,
          [action.email]: 'failure',
        },
      }

    case emailConfirmActions.REQUEST:
      return { ...state, emailConfirmState: 'loading' }

    case emailConfirmActions.REQUEST_SUCCESS:
      return { ...state, emailConfirmState: 'success' }

    case emailConfirmActions.REQUEST_FAILURE:
      return { ...state, emailConfirmState: 'failure' }

    case demographicsSubmitActions.REQUEST:
      return { ...state, demographicsState: 'loading' }

    case demographicsSubmitActions.REQUEST_SUCCESS:
      return { ...state, demographicsState: 'success' }

    case demographicsSubmitActions.REQUEST_FAILURE:
      return { ...state, demographicsState: 'failure' }

    case demographicsUpdateActions.REQUEST:
      return { ...state, demographicsState: 'loading' }

    case demographicsUpdateActions.REQUEST_SUCCESS:
      return { ...state, demographicsState: 'success' }

    case demographicsUpdateActions.REQUEST_FAILURE:
      return { ...state, demographicsState: 'failure' }

    case demographicsGetActions.REQUEST:
      return { ...state, demographicsGetState: 'loading' }

    case demographicsGetActions.REQUEST_SUCCESS:
      return {
        ...state,
        demographicsGetState: 'success',
        demographicsData: action.data,
      }

    case demographicsGetActions.REQUEST_FAILURE:
      return { ...state, demographicsGetState: 'failure' }

    case inviteUsers.REQUEST:
      return { ...state, inviteUsersState: 'loading' }

    case inviteUsers.REQUEST_SUCCESS:
      return { ...state, inviteUsersState: 'success' }

    case inviteUsers.REQUEST_FAILURE:
      return { ...state, inviteUsersState: 'failure' }

    case inviteIndividualUser.REQUEST:
      return {
        ...state,
        inviteIndividualUserStates: {
          ...state.inviteIndividualUserStates,
          [action.email]: 'loading',
        },
      }

    case inviteIndividualUser.REQUEST_SUCCESS:
      return {
        ...state,
        inviteIndividualUserStates: {
          ...state.inviteIndividualUserStates,
          [action.email]: 'success',
        },
      }

    case inviteIndividualUser.REQUEST_FAILURE:
      return {
        ...state,
        inviteIndividualUserStates: {
          ...state.inviteIndividualUserStates,
          [action.email]: 'failure',
        },
      }

    case createProductActions.REQUEST:
      return {
        ...state,
        createProductStates: {
          ...state.createProductStates,
          [action.query]: { state: 'loading' },
        },
      }

    case createProductActions.REQUEST_SUCCESS:
      return {
        ...state,
        createProductStates: {
          ...state.createProductStates,
          [action.query]: { state: 'success' },
        },
      }

    case createProductActions.REQUEST_FAILURE:
      return {
        ...state,
        createProductStates: {
          ...state.createProductStates,
          [action.query]: {
            state: 'failure',
            err: action.error.response.data.message,
          },
        },
      }

    case updateProductSubscriptionActions.REQUEST:
      return {
        ...state,
        updateProductSubscriptionStates: 'loading',
      }

    case updateProductSubscriptionActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateProductSubscriptionStates: 'success',
      }

    case updateProductSubscriptionActions.REQUEST_FAILURE:
      return {
        ...state,
        updateProductSubscriptionStates: 'failure',
      }

    case deleteProductActions.REQUEST:
      return {
        ...state,
        deleteProductStates: 'loading',
      }

    case deleteProductActions.REQUEST_SUCCESS:
      return {
        ...state,
        deleteProductStates: 'success',
      }

    case deleteProductActions.REQUEST_FAILURE:
      return {
        ...state,
        deleteProductStates: 'failure',
      }

    case reactivateProductActions.REQUEST:
      return {
        ...state,
        reactivateProductStates: 'loading',
      }

    case reactivateProductActions.REQUEST_SUCCESS:
      return {
        ...state,
        reactivateProductStates: 'success',
      }

    case reactivateProductActions.REQUEST_FAILURE:
      return {
        ...state,
        reactivateProductStates: 'failure',
      }

    case deleteCreditsActions.REQUEST:
      return {
        ...state,
        deleteCreditState: 'loading',
        deleteCreditTypeState: {
          ...state.deleteCreditTypeState,
          [action.creditType]: 'loading',
        },
      }
    case deleteCreditsActions.REQUEST_SUCCESS:
      return {
        ...state,
        deleteCreditState: 'success',
        deleteCreditTypeState: {
          ...state.deleteCreditTypeState,
          [action.creditType]: 'success',
        },
      }
    case deleteCreditsActions.REQUEST_FAILURE:
      return {
        ...state,
        deleteCreditState: 'failure',
        deleteCreditTypeState: {
          ...state.deleteCreditTypeState,
          [action.creditType]: 'failure',
        },
      }

    // Notifications
    case getNotificationsActions.REQUEST:
      return { ...state, notificationsState: 'loading' }
    case getNotificationsActions.REQUEST_SUCCESS:
      return {
        ...state,
        notificationsState: 'success',
        notifications: action.notifications,
      }
    case getNotificationsActions.REQUEST_FAILURE:
      return { ...state, notificationsState: 'failure' }

    case deleteNotificationsActions.REQUEST:
      return { ...state, notificationsDeleteState: 'loading' }
    case deleteNotificationsActions.REQUEST_SUCCESS:
      return { ...state, notificationsDeleteState: 'success' }
    case deleteNotificationsActions.REQUEST_FAILURE:
      return { ...state, notificationsDeleteState: 'failure' }

    case invitePeerAssessments.REQUEST:
      return {
        ...state,
        invitePeerAssessmentsStates: {
          ...state.invitePeerAssessmentsStates,
          [action.profile_id]: 'loading',
        },
      }
    case invitePeerAssessments.REQUEST_SUCCESS:
      return {
        ...state,
        invitePeerAssessmentsStates: {
          ...state.invitePeerAssessmentsStates,
          [action.profile_id]: 'success',
        },
      }
    case invitePeerAssessments.REQUEST_FAILURE:
      return {
        ...state,
        invitePeerAssessmentsStates: {
          ...state.invitePeerAssessmentsStates,
          [action.profile_id]: 'failure',
        },
      }

    case emailInviteAssessment.REQUEST:
      return {
        ...state,
        emailInviteAssessmentStates: 'loading',
      }

    case emailInviteAssessment.REQUEST_SUCCESS:
      return {
        ...state,
        emailInviteAssessmentStates: 'success',
      }

    case emailInviteAssessment.REQUEST_FAILURE:
      return {
        ...state,
        emailInviteAssessmentStates: 'failure',
      }

    case updatePrivacyActions.REQUEST:
      return { ...state, updatePrivacyState: 'loading' }
    case updatePrivacyActions.REQUEST_SUCCESS:
      return { ...state, updatePrivacyState: 'success' }
    case updatePrivacyActions.REQUEST_FAILURE:
      return { ...state, updatePrivacyState: 'failure' }

    // Assign Product Licenses
    case assignProductLicenseActions.REQUEST:
      return { ...state, assignProductLicenseState: 'loading' }
    case assignProductLicenseActions.REQUEST_SUCCESS:
      return { ...state, assignProductLicenseState: 'success' }
    case assignProductLicenseActions.REQUEST_FAILURE:
      return { ...state, assignProductLicenseState: 'failure' }

    // Unassign Product Licenses
    case unassignProductLicenseActions.REQUEST:
      return {
        ...state,
        unassignProductLicenseStates: 'loading',
      }
    case unassignProductLicenseActions.REQUEST_SUCCESS:
      return {
        ...state,
        unassignProductLicenseStates: 'success',
      }
    case unassignProductLicenseActions.REQUEST_FAILURE:
      return {
        ...state,
        unassignProductLicenseStates: 'failure',
      }

    // Create API Token
    case createAPICustomerTokenActions.REQUEST:
      return {
        ...state,
        APICustomerTokenState: 'loading',
      }
    case createAPICustomerTokenActions.REQUEST_SUCCESS:
      return {
        ...state,
        APICustomerTokenState: 'success',
        APICustomerToken: action.token,
        APICustomerId: action.id,
      }
    case createAPICustomerTokenActions.REQUEST_FAILURE:
      return {
        ...state,
        APICustomerTokenState: 'failure',
      }

    // API Customer Stats
    case APICustomerStatsActions.REQUEST:
      return {
        ...state,
        APICustomerStatsState: 'loading',
      }
    case APICustomerStatsActions.REQUEST_SUCCESS:
      return {
        ...state,
        APICustomerStatsState: 'success',
        APICustomerStats: action.data,
      }
    case APICustomerStatsActions.REQUEST_FAILURE:
      return {
        ...state,
        APICustomerStatsState: 'failure',
      }

    // Connect Social
    case ConnectSocialActions.REQUEST:
      return {
        ...state,
        connectSocialState: 'loading',
      }
    case ConnectSocialActions.REQUEST_SUCCESS:
      return {
        ...state,
        connectSocialState: 'success',
      }
    case ConnectSocialActions.REQUEST_FAILURE:
      return {
        ...state,
        connectSocialState: 'failure',
      }

    // Get Receipts
    case getChargeActions.REQUEST:
      return {
        ...state,
        chargeStates: {
          ...state.chargeStates,
          [action.chargeId]: 'loading',
        },
      }
    case getChargeActions.REQUEST_SUCCESS:
      return {
        ...state,
        chargeStates: {
          ...state.chargeStates,
          [action.chargeId]: 'success',
        },
        chargeResults: {
          ...state.chargeResults,
          [action.chargeId]: action.receipt,
        },
      }
    case getChargeActions.REQUEST_FAILURE:
      return {
        ...state,
        chargeStates: {
          ...state.chargeStates,
          [action.chargeId]: 'failure',
        },
      }

    // Pause product
    case pauseProductActions.REQUEST:
      return {
        ...state,
        pauseProductStates: 'loading',
      }
    case pauseProductActions.REQUEST_SUCCESS:
      return {
        ...state,
        pauseProductStates: 'success',
      }
    case pauseProductActions.REQUEST_FAILURE:
      return {
        ...state,
        pauseProductStates: 'failure',
      }

    // Get Integration Tokens
    case getIntegrationTokensActions.REQUEST:
      return {
        ...state,
        getIntegrationTokensStates: {
          ...state.getIntegrationTokensStates,
          [action.key]: 'loading',
        },
      }
    case getIntegrationTokensActions.REQUEST_SUCCESS:
      return {
        ...state,
        getIntegrationTokensStates: {
          ...state.getIntegrationTokensStates,
          [action.key]: 'success',
        },
        getIntegrationTokenResults: {
          ...state.getIntegrationTokenResults,
          [action.key]: action.data,
        },
      }
    case getIntegrationTokensActions.REQUEST_FAILURE:
      return {
        ...state,
        getIntegrationTokensStates: {
          ...state.getIntegrationTokensStates,
          [action.key]: 'failure',
        },
      }

    // Create Integration Token
    case createIntegrationTokenActions.REQUEST:
      return {
        ...state,
        createIntegrationTokenStates: {
          ...state.createIntegrationTokenStates,
          [action.key]: 'loading',
        },
      }
    case createIntegrationTokenActions.REQUEST_SUCCESS:
      return {
        ...state,
        createIntegrationTokenStates: {
          ...state.createIntegrationTokenStates,
          [action.key]: 'success',
        },
      }
    case createIntegrationTokenActions.REQUEST_FAILURE:
      return {
        ...state,
        createIntegrationTokenStates: {
          ...state.createIntegrationTokenStates,
          [action.key]: 'failure',
        },
      }

    // Update Integration Token
    case updateIntegrationTokenActions.REQUEST:
      return {
        ...state,
        updateIntegrationTokenStates: {
          ...state.updateIntegrationTokenStates,
          [action.key]: 'loading',
        },
      }
    case updateIntegrationTokenActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateIntegrationTokenStates: {
          ...state.updateIntegrationTokenStates,
          [action.key]: 'success',
        },
      }
    case updateIntegrationTokenActions.REQUEST_FAILURE:
      return {
        ...state,
        updateIntegrationTokenStates: {
          ...state.updateIntegrationTokenStates,
          [action.key]: 'failure',
        },
      }

    // Get Checklist
    case getChecklistActions.REQUEST:
      return {
        ...state,
        checklistState: 'loading',
      }
    case getChecklistActions.REQUEST_SUCCESS:
      return {
        ...state,
        checklistState: 'success',
        checklist: action.data,
      }
    case getChecklistActions.REQUEST_FAILURE:
      return {
        ...state,
        checklistState: 'failure',
      }

    // Update Checklist mode
    case updateChecklistActions.REQUEST:
      return {
        ...state,
        updateChecklistState: 'loading',
      }
    case updateChecklistActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateChecklistState: 'success',
      }
    case updateChecklistActions.REQUEST_FAILURE:
      return {
        ...state,
        updateChecklistState: 'failure',
      }

    // Update Checklist item
    case completeChecklistItemActions.REQUEST:
      return {
        ...state,
        completeChecklistItemStates: {
          ...state.completeChecklistItemStates,
          [action.key]: 'loading',
        },
      }
    case completeChecklistItemActions.REQUEST_SUCCESS:
      return {
        ...state,
        completeChecklistItemStates: {
          ...state.completeChecklistItemStates,
          [action.key]: 'success',
        },
      }
    case completeChecklistItemActions.REQUEST_FAILURE:
      return {
        ...state,
        completeChecklistItemStates: {
          ...state.completeChecklistItemStates,
          [action.key]: 'failure',
        },
      }

    // Get Public Relationship Data
    case qrActions.REQUEST:
      return {
        ...state,
        qrState: 'loading',
      }
    case qrActions.REQUEST_SUCCESS:
      return {
        ...state,
        qrState: 'success',
        qrResult: action.data,
      }
    case qrActions.REQUEST_FAILURE:
      return {
        ...state,
        qrState: 'failure',
      }

    // Update Product
    case updateProductActions.REQUEST:
      return {
        ...state,
        updateProductStates: {
          ...state.updateProductStates,
          [action.key]: 'loading',
        },
      }
    case updateProductActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateProductStates: {
          ...state.updateProductStates,
          [action.key]: 'success',
        },
      }
    case updateProductActions.REQUEST_FAILURE:
      return {
        ...state,
        updateProductStates: {
          ...state.updateProductStates,
          [action.key]: 'failure',
        },
      }

    // Preview Product
    case previewProductActions.REQUEST:
      return {
        ...state,
        previewProductStates: {
          ...state.previewProductStates,
          [action.key]: 'loading',
        },
      }
    case previewProductActions.REQUEST_SUCCESS:
      return {
        ...state,
        previewProductStates: {
          ...state.previewProductStates,
          [action.key]: 'success',
        },
        previewProductResults: {
          ...state.previewProductResults,
          [action.key]: action.data,
        },
      }
    case previewProductActions.REQUEST_FAILURE:
      return {
        ...state,
        previewProductStates: {
          ...state.previewProductStates,
          [action.key]: 'failure',
        },
      }

    // CRM Integrations Index
    case loadCrmIntegrationsActions.REQUEST:
      return {
        ...state,
        crmIntegrationsState: 'loading',
      }
    case loadCrmIntegrationsActions.REQUEST_SUCCESS:
      return {
        ...state,
        crmIntegrationsState: 'success',
        crmIntegrations: action.data,
      }
    case loadCrmIntegrationsActions.REQUEST_FAILURE:
      return {
        ...state,
        crmIntegrationsState: 'failure',
      }

    // Update CRM Integration
    case updatecrmIntegrationActions.REQUEST:
      return {
        ...state,
        updateCrmIntegrationStates: {
          ...state.updateCrmIntegrationStates,
          [action.key]: 'loading',
        },
      }
    case updatecrmIntegrationActions.REQUEST_SUCCESS:
      return {
        ...state,
        updateCrmIntegrationStates: {
          ...state.updateCrmIntegrationStates,
          [action.key]: 'success',
        },
      }
    case updatecrmIntegrationActions.REQUEST_FAILURE:
      return {
        ...state,
        updateCrmIntegrationStates: {
          ...state.updateCrmIntegrationStates,
          [action.key]: 'failure',
        },
      }

    // Find CRM Integration
    case findcrmIntegrationActions.REQUEST:
      return {
        ...state,
        findCrmIntegrationStates: {
          ...state.findCrmIntegrationStates,
          [action.key]: 'loading',
        },
      }
    case findcrmIntegrationActions.REQUEST_SUCCESS:
      return {
        ...state,
        findCrmIntegrationStates: {
          ...state.findCrmIntegrationStates,
          [action.key]: 'success',
        },
        findCrmIntegrationResults: {
          ...state.findCrmIntegrationResults,
          [action.key]: action.data,
        },
      }
    case findcrmIntegrationActions.REQUEST_FAILURE:
      return {
        ...state,
        findCrmIntegrationStates: {
          ...state.findCrmIntegrationStates,
          [action.key]: 'failure',
        },
      }

    // Connect Salesforce API actions
    case connectSalesforceApiActions.REQUEST:
      return {
        ...state,
        connectSalesforceApiState: 'loading',
      }
    case connectSalesforceApiActions.REQUEST_SUCCESS:
      return {
        ...state,
        connectSalesforceApiState: 'success',
        connectSalesforceApiResult: action.data,
      }
    case connectSalesforceApiActions.REQUEST_FAILURE:
      return {
        ...state,
        connectSalesforceApiState: 'failure',
      }

    // Criteria
    case criteriaActions.REQUEST:
      return {
        ...state,
        criteriaStates: {
          ...state.criteriaStates,
          [action.key]: 'loading',
        },
      }
    case criteriaActions.REQUEST_SUCCESS:
      return {
        ...state,
        criteriaStates: {
          ...state.criteriaStates,
          [action.key]: 'success',
        },
        criteriaResults: {
          ...state.criteriaResults,
          [action.key]: action.data,
        },
      }
    case criteriaActions.REQUEST_FAILURE:
      return {
        ...state,
        criteriaStates: {
          ...state.criteriaStates,
          [action.key]: 'failure',
        },
      }
    case suggestCompanyActions.REQUEST_SUCCESS:
      return {
        ...state,
        suggestedCompanies: action.data,
      }

    case createToastAction.REQUEST:
      return {
        ...state,
        toastMessages: [...state.toastMessages, action.toast],
      }

    case dismissToastAction.REQUEST:
      const toastToUpdate = state.toastMessages.find(
        toast => toast.id === action.toastId,
      )
      if (!toastToUpdate) return { ...state }

      return {
        ...state,
        toastMessages: [
          { ...toastToUpdate, read: true },
          ...state.toastMessages.filter(toast => toast.id !== action.toastId),
        ],
      }

    default:
      return state
  }
}
