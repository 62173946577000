import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { deleteCookie, getCookie } from '@dashboard/lib/browser'
import * as ls from 'local-storage'
import { appName, sessionToken } from '@dashboard/lib/user'
import { SegmentTypes } from '@dashboard/lib/types'
import { newUserRegistered } from '@dashboard/lib/analytics'
import { fetchAuthApi } from '@crystal-eyes/utils/apis/authApi'
import {
  AUTH_REFRESH_COOKIE,
  AUTH_TOKEN_COOKIE,
} from '@crystal-eyes/lib/constants'
import { CRYSTAL_AUTH_TOKEN } from '@dashboard/lib/constants'
import { setCookie } from '@dashboard/lib/browser'

export interface RegistrationParams {
  email: string
  password: string
  firstName: string
  lastName: string
  gender?: string
  companyName?: string
  companyDomain?: string
  source?: string
  companyInviteToken?: string
  gToken?: string
  profileId?: string
  actions?: string
  inviteToken?: string
  checkout_token?: string
  current_segment?: SegmentTypes
  team_id?: string
  team_unit_id?: string
  companyWebsite: string
  captchaResponse?: string | null
}

export const REQUEST = 'REGISTER_START'
type RequestAction = { type: typeof REQUEST }
const request = (): RequestAction => ({
  type: REQUEST,
})

export const REQUEST_SUCCESS = 'REGISTER_SUCCESS'
type RequestSuccessAction = {
  type: typeof REQUEST_SUCCESS
  token: string
}
const requestSuccess = (token: string): RequestSuccessAction => ({
  type: REQUEST_SUCCESS,
  token,
})

export const REQUEST_FAILURE = 'REGISTER_FAILURE'
type RequestFailureAction = {
  type: typeof REQUEST_FAILURE
  error?: {
    message?: string
    parameter?: string
  }
}
const requestFailure = (error?: {
  message?: string
  parameter?: string
}): RequestFailureAction => ({
  type: REQUEST_FAILURE,
  error,
})

export type Action = RequestAction | RequestSuccessAction | RequestFailureAction

export function registerAttributes(attributes: RegistrationParams) {
  return async (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
    return new Promise<string>((resolve, reject) => {
      dispatch(request())

      interface EndpointParams {
        user: {
          email: string
          password: string
          first_name: string
          last_name: string
          gender?: string
          session_app: string
          session_token: string
          company_website?: string
        }
        company_name?: string
        company_domain?: string
        company_source?: string
        gtoken?: string
        profile_id?: string
        invite_token?: string
        company_invite_token?: string
        actions?: string
        enneagram?: string
        myers_briggs?: string
        slack_team_id?: string
        api_customer_id?: string
        facebook_id?: string
        checkout_token?: string
        personality?: { degrees: string; intensity: string }
        current_segment?: SegmentTypes
        team_id?: string
        team_unit_id?: string
        captcha_response?: string
      }

      const params: EndpointParams = {
        user: {
          email: attributes.email,
          password: attributes.password,
          first_name: attributes.firstName,
          last_name: attributes.lastName,
          session_app: appName(),
          session_token: sessionToken(),
        },
        company_name: attributes.companyName,
        company_domain: attributes.companyDomain,
        company_source: attributes.source,
      }

      if (attributes.gToken) {
        params.gtoken = attributes.gToken
      }

      if (attributes.gender) {
        params.user.gender = attributes.gender
      }

      if (attributes.profileId) {
        params.profile_id = attributes.profileId
      }

      if (attributes.inviteToken) {
        params.invite_token = attributes.inviteToken
      }

      if (attributes.companyInviteToken) {
        params.company_invite_token = attributes.companyInviteToken
      }

      if (attributes.actions) {
        params.actions = attributes.actions
      }

      if (attributes.checkout_token) {
        params.checkout_token = attributes.checkout_token
      }

      if (attributes.current_segment) {
        params.current_segment = attributes.current_segment
      }

      if (attributes.captchaResponse) {
        params.captcha_response = attributes.captchaResponse
      }

      if (attributes.team_id) params.team_id = attributes.team_id

      if (attributes.team_unit_id) params.team_unit_id = attributes.team_unit_id

      if (attributes.companyWebsite)
        params.user.company_website = attributes.companyWebsite

      const slackTeamId = ls.get<string | void>('slackTeamId')
      const apiCustomerId = ls.get<string | void>('apiCustomerId')
      const facebookID = ls.get<string | void>('facebookID')
      const enneagram = getCookie('enneagram')
      const myersBriggs = getCookie('myers_briggs')
      const degrees = getCookie('disc_degrees')
      const intensity = getCookie('disc_intensity')

      if (enneagram) params.enneagram = enneagram

      if (myersBriggs) params.myers_briggs = myersBriggs

      if (slackTeamId) {
        params.slack_team_id = slackTeamId
      }

      if (apiCustomerId) {
        params.api_customer_id = apiCustomerId
      }

      if (facebookID) {
        params.facebook_id = facebookID
      }

      if (degrees && degrees.length > 0 && intensity && intensity.length > 0) {
        params.personality = { degrees, intensity }
      }

      fetchAuthApi('registration', {
        method: 'POST',
        body: JSON.stringify(params),
      })
        .then(resp => resp.json())
        .then(async data => {
          ls.remove('publicAssessmentUUID')
          ls.remove('slackTeamId')
          ls.remove('apiCustomerId')
          deleteCookie('public_assessment_uuid')
          deleteCookie('enneagram')
          deleteCookie('myers_briggs')
          deleteCookie('disc_degrees')
          deleteCookie('disc_intensity')

          setCookie(AUTH_REFRESH_COOKIE, data.data.token)
          setCookie(CRYSTAL_AUTH_TOKEN, data.data.access_token)
          setCookie(AUTH_TOKEN_COOKIE, data.data.access_token)
          dispatch(requestSuccess(data.data.access_token))
          if (data.data.access_token) {
            // hubspot
            newUserRegistered(
              attributes.email,
              attributes.firstName,
              attributes.lastName,
              attributes.companyName,
            )
          }
          resolve(data.data.access_token)
        })
        .catch((err: any) => {
          const formattedError = err.body?.message
            ? { message: err.body?.message }
            : { message: err.body?.error }

          dispatch(requestFailure(formattedError))
          reject(
            `Error calling registerUser (Status: ${
              err.status
            }): ${JSON.stringify(err)}`,
          )
        })
    })
  }
}
